.login_sub_heading,
label {
  color: var(--light-text);
  font-size: var(--p);
  font-weight: 400;
  line-height: 21px;
}
.form-control {
  padding: 0.575rem 0rem;
  font-size: var(--p);
  font-weight: 600;
  line-height: 1.5;
  border: none;
  border-bottom: 1px solid var(--border-light);
  border-radius: 0;
  color: var(--light-text) !important;
}
.input-group-text {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid var(--border-light);
  border-radius: 0;
}
.form-control:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

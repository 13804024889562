.modal-content {
  padding: 0.8rem;
  .modal-header {
    border-bottom: 0;
    .btn-close {
      padding: 0.3rem;
      width: 6px;
      height: 6px;
      margin-right: 0.5rem;
      &:focus {
        box-shadow: none;
      }
    }
    .modal-title {
      font-size: var(--p1);
      font-weight: 600;
    }
  }
  .modal-body {
    border-bottom: 0;
    max-height: 60vh;
    overflow: auto;
  }
  .scroll_down {
    position: absolute;
    bottom: 80px;
    right: 10px;
    animation: bounce 1s infinite;
    button {
      background-color: transparent;
    }
  }
  .modal-footer {
    border-top: 0;
    position: relative;
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.carousel .carousel-status {
    color: var(--primary) !important;

    li .dot {
        color: var(--primary) !important;
    }

    li .dot .selected {
        color: var(--light-text) !important;
    }
}